<template>
  <div class="table-responsive m-0 p-0">
    <b-table
      :items="workHistory"
      :per-page="query.per_page"
      responsive
      :fields="tableFields"
      :sort-by.sync="sortBy"
      show-empty
      empty-text="No matching records found"
      :sort-desc.sync="isSortDirDesc"
      class="position-relative shadow-md bg-white text-left"
    >
      <template #cell(ID)="row">
        <div
          id="indicator-unengaged"
          class="d-flex flex-row"
        >
          <div :class="row.item.indicator"></div>
          <div class="d-flex align-items-center">
            <p class="m-0">{{ row.item.id }}</p>
          </div>
        </div>
      </template>
      <template #cell(Date)="row">
        <p class="m-0">{{ row.item.roster.date ? formateDate(row.item.roster.date) : "N/A" }}</p>
      </template>
      <template #cell(AssignedEmployee)="row">
        <p class="m-0">{{ row.item.employee.name }}</p>
      </template>
      <template #cell(AssignedTo)="row">
        <p class="m-0" v-if="row.item.roster && row.item.roster.customer">
          {{
            row.item.roster.customer.name
              ? row.item.roster.customer.name
              : "N/A"
          }}
        </p>
      </template>
      <template #cell(total_hours)="row">
        <span>{{ getSum(row.item.hours.ordinary, row.item.hours.otOne, row.item.hours.otTwo) }} </span>
      </template>
      <template #cell(Mobile)="row">
        <p class="m-0">{{ row.item.employee.phone ? row.item.employee.phone : "N/A" }}</p>
      </template>
      <template #cell(JobRole)="row">
        <p class="m-0">{{ row.item.employee.sector ? row.item.employee.sector : "N/A" }}</p>
      </template>
      <template #cell(Email)="row">
        <p class="m-0">{{ row.item.employee.email ? row.item.employee.email : "N/A" }}</p>
      </template>
    </b-table>
    <div>
      <b-pagination
        class="mt-3 p-1 d-flex justify-content-end bg-white"
        v-if="total > query.per_page"
        v-model="query.page"
        :total-rows="total"
        :per-page="query.per_page"
        aria-controls="my-table"
        @change="getPageNumber($event)"
        pills
        size="lg"
        page-class="info"
      ></b-pagination>
    </div>
  </div>
</template>

<script>
import { calculateTotalHours } from "@/utils/calculation";
import moment from "moment"
import {
  BCalendar,
  BCard,
  BCol,
  BContainer,
  BDropdown,
  BDropdownItem,
  BPagination,
  BRow,
  BTable,
} from "bootstrap-vue";

export default {
  props: {
    workHistory: {
      type: Array,
      default: () => [],
    },
  },
  components: {
    BCard,
    BTable,
    BCalendar,
    BCard,
    BCol,
    BContainer,
    BDropdown,
    BDropdownItem,
    BRow,
    BPagination,
  },

  data() {
    return {
      // main table is here
      sortBy: "ID",
      isSortDirDesc: false,
      sortDesc: false,
      query: {
        page: 1,
        per_page: 20,
      },
      total: 0,
      tableFields: [
        // { key: 'Status', label: '', sortable: true },
        { key: "ID", label: "ID ", sortable: true },
        { key: "Date", label: "Date", sortable: true },
        { key: "AssignedEmployee", label: "Assigned Employee", sortable: true },
        { key: "AssignedTo", sortable: true },
        { key: "total_hours" },
        { key: "Mobile", sortable: true },
        { key: "JobRole", sortable: true },
        { key: "Email", sortable: true },
      ],
    };
  },
  methods: {
    getSum(ordinary, otOne, otTwo){
      return calculateTotalHours(ordinary, otOne, otTwo)
    },
    formateDate(date) {
      // format date to 12th Aug, 2022
      return moment(date).format("DD-MM-YYYY");
    },
    getPageNumber(page) {
      if (page && typeof page === "number"){
        this.query.page = page;
        this.$emit("childClick", page);
      }
    },
  },
};
</script>

<style scoped>
.active-indicator {
  background-color: #50c878;
  height: 40px;
  width: 4px;
  padding: 0;
}

.Unengaged-indicator {
  background-color: #ff4f5d;
  height: 40px;
  width: 4px;
}

#indicator-unengaged [dir] .table th,
[dir] .table td {
  padding: 0rem 0rem !important;
}

#indicator-active [dir] .table th,
[dir] .table td {
  padding: 0rem 0rem;
}

.active-success {
  background-color: #50c878;
  height: 28px;
  width: 4px;
}

.active-danger {
  background-color: #ff4f5d;
  height: 28px;
  width: 4px;
}
</style>
