<template>
  <div>
    <div class="rounded-lg shadow-lg mb-2">
      <basic-information :company="company" />
    </div>
    <div
      v-if="$can('list', 'Supervisor')"
      class="rounded-lg shadow-lg p-2 mb-2"
    >
      <div class="row">
        <div class="col-12 py-2 d-flex justify-content-between">
          <h1>Supervisor List</h1>
          <span>
            <router-link
              v-if="$can('create', 'Supervisor')"
              :to="`/company/supervisor-create/${$route.params.id}`"
            >
              <b-button class="btn border mt-1 mt-lg-0 add-btn d-block ml-auto">
                <div class="d-flex flex-row align-items-center">
                  <span class="pr-1"> Add Supervisor </span>
                  <span>
                    <img
                      src="@/assets/images/icons/add-icon.png"
                      alt="add icon"
                      height="20"
                      width="20"
                    />
                  </span>
                </div>
              </b-button>
            </router-link>
          </span>
        </div>
        <div class="col-12 py-2 d-flex justify-content-between">
          <supervisor-list class="w-100" />
        </div>
      </div>
    </div>

    <div class="rounded-lg shadow-lg p-2 mt-2">
      <div class="col-12">
        <div class="d-flex justify-content-between align-items-center">
          <h1>Customers</h1>
          <div class="d-flex flex-row align-items-center">
            <label for="" class="label-font">Status :</label>
            <button @click="changeStatus(0, 'customer')" class="btn">
              <span class="active-success"></span>
              <span :class="customerQuery.status === 0 ? 'has-border' : ''"
                >Active</span
              >
            </button>
            <button @click="changeStatus(1, 'customer')" class="btn p-0">
              <span class="active-danger"></span>
              <span :class="customerQuery.status === 1 ? 'has-border' : ''"
                >Inactive</span
              >
            </button>
          </div>
        </div>
        <hr />
        <b-table
          class="data-text-custom"
          striped
          hover
          :items="customers"
          :fields="customerFields"
        >
          <template #cell(sl)="data">
            <div class="d-flex flex-row">
              <div
                :class="`${
                  data.item.status === 0 && data.item.user_status === 'active'
                    ? 'active-success'
                    : 'active-danger'
                }`"
              ></div>
              <span class="ml-1">{{ data.index + fromCustomer }}</span>
            </div>
          </template>
          
          <template #cell(address)="data">
            <span v-if="data.item.customer && data.item.customer.city">{{
              data.item.customer.city
            }}</span
            >,
            <span v-if="data.item.customer && data.item.customer.address">{{
              data.item.customer.address
            }}</span>
          </template>
          <template #cell(action)="data">
            <b-link
              v-if="$can('view', 'Client')"
              :to="`/customer/view/${data.item.id}`"
              class="btn btn-info btn-sm"
            >
              View
            </b-link>
          </template>
        </b-table>
        <b-pagination
          class="mt-3 p-1 d-flex justify-content-end bg-white"
          v-if="totalCustomer > customerQuery.per_page"
          v-model="customerQuery.page"
          :total-rows="totalCustomer"
          :per-page="customerQuery.per_page"
          aria-controls="my-table"
          @change="getCustomers($event)"
          pills
          size="lg"
          page-class="info"
        ></b-pagination>
      </div>
    </div>
    <div class="rounded-lg shadow-lg p-2 mt-2">
      <div class="col-12">
        <div class="d-flex justify-content-between align-items-center">
          <h1>Employees</h1>
          <div class="d-flex flex-row align-items-center">
            <label for="" class="label-font">Status :</label>
            <button @click="changeStatus(0, 'employee')" class="btn">
              <span class="active-success"></span>
              <span :class="empQuery.status === 0 ? 'has-border' : ''"
                >Active</span
              >
            </button>
            <button @click="changeStatus(1, 'employee')" class="btn p-0">
              <span class="active-warning"></span>
              <span :class="empQuery.status === 1 ? 'has-border' : ''"
                >Inactive</span
              >
            </button>
            <button @click="changeStatus(2, 'employee')" class="btn">
              <span class="active-danger"></span>
              <span :class="empQuery.status === 2 ? 'has-border' : ''"
                >Company/Client Free</span
              >
            </button>
          </div>
        </div>
        <hr />
        <b-table
          class="data-text-custom"
          striped
          hover
          :items="employees"
          :fields="employeeFields"
        >
          <template #cell(sl)="data">
            <div class="d-flex flex-row">
              <div
                :class="`${
                  data.item.status === 0 && data.item.user_status === 'active'
                    ? 'active-success'
                    : data.item.status === 0 && data.item.user_status === 'free' ? 'active-danger' : 'active-warning'
                }`"
              ></div>
              <span class="ml-1">{{ data.index + fromEmployee }}</span>
            </div>
          </template>
          <template #cell(idn)="data">
            {{ data.item.serial_number }}
          </template>
          <template #cell(address)="data">
            {{ data.item.employee.address }}
          </template>
          <template #cell(action)="data">
            <b-link
              v-if="$can('view', 'Employee')"
              :to="`/employee-preview/${data.item.id}`"
              class="btn btn-info btn-sm"
            >
              View
            </b-link>
          </template>
        </b-table>
        <b-pagination
          class="mt-3 p-1 d-flex justify-content-end bg-white"
          v-if="totalEmployee > empQuery.per_page"
          v-model="empQuery.page"
          :total-rows="totalEmployee"
          :per-page="empQuery.per_page"
          aria-controls="my-table"
          @change="getEmployees($event)"
          pills
          size="lg"
          page-class="info"
        ></b-pagination>
      </div>
    </div>
    <div v-if="$can('list', 'Timesheet')" class="rounded-lg shadow-lg p-2 mt-2">
      <div class="row">
        <div class="col-12 col-md-4 py-2">
          <h1>Work History</h1>
        </div>
        <div class="col-12 col-md-4">
          <b-form-group class="mr-1" label="Search:" label-for="Search">
            <b-form-group class="mr-1 border border-primary rounded">
              <b-form-input
                v-debounce:300="getRosterWorkList"
                v-model="query.employee"
                class="border bg-light border border-primary"
                placeholder="Search..."
                type="text"
                size="md"
              />
            </b-form-group>
          </b-form-group>
        </div>
        <div class="col-12 col-md-4">
          <b-form-group
            class="mr-1"
            label="Filter By Date:"
            label-for="Filter By Date"
          >
            <b-form-group class="mr-1 border border-primary rounded my-date-range">
              <flat-pickr
                v-model="rangeDate"
                class="form-control bg-light border-dark-1"
                :config="{ mode: 'range', altInput: true }"
                placeholder="Select Date Range & Filter"
                v-debounce:500="getRosterWorkList"
              />
              <button
                v-if="rangeDate"
                class="btn btn-sm btn-danger text-end d-block ml-1"
                type="button"
                title="Clear"
                data-clear
                @click="clearDate"
              >
                <span>X</span>
              </button>
            </b-form-group>
          </b-form-group>
        </div>
      </div>

      <work-history-table
        :workHistory="employeeWorkHistory"
        :query="query"
        :total="total"
        @childClick="getRosterWorkList"
      />
    </div>
  </div>
</template>
<script>
// import availableTimeSheets from "@/components/available-timesheet-components/time-sheet-table.vue";
import workHistoryTable from "@/components/work-history-components/employee-preview-table.vue";
import {
  BCard,
  BCol,
  BFormGroup,
  BFormInput,
  BRow,
  BButton,
  BTable,
  BLink,
  BPagination,
} from "bootstrap-vue";
import vSelect from "vue-select";
import basicInformation from "./components/company-basic-information.vue";
import SupervisorList from "./supervisor/supervisor-list.vue";
import flatPickr from "vue-flatpickr-component";
import { getLogedInUser } from "@/utils/auth";
export default {
  data() {
    return {
      logedInUser: JSON.parse(getLogedInUser()),
      industries: [],
      selected: { title: "Square" },
      employeeWorkHistory: [],
      customers: [],
      supervisorList: [],
      total: 0,
      rangeDate: null,
      company: {
        first_name: "",
        last_name: "",
        email: "",
        phone: "",
        name: "",
        info: {
          industry: "",
          abn: "",
          acn: "",
          tfn: "",
          city: "",
          state: "",
        },
      },
      customer: {
        contacts: [
          {
            id: null,
            first_name: "",
            last_name: "",
            email: "",
            phone: "",
            designation: "",
          },
        ],
      },
      customerQuery: {
        page: 1,
        per_page: 20,
        status: 0,
      },
      empQuery: {
        page: 1,
        per_page: 20,
        status: 0,
      },
      query: {
        name: "",
        page: 1,
        per_page: 20,
        id: "",
        industry_id: "",
        employee: "",
      },
      customer: {},
      employees: [],
      totalEmployee: 0,
      from: 0,
      fromEmployee: 0,
      totalCustomer: 0,
      fromCustomer: 0,
      rangeDate: null,
      employeeFields: [
        "sl",
        "idn",
        "name",
        "phone",
        "email",
        "address",
        "action",
      ],
      customerFields: [
        "sl",
        "id",
        "name",
        "phone",
        "email",
        "address",
        "action",
      ],
    };
  },
  components: {
    BRow,
    BCol,
    BCard,
    BFormGroup,
    BFormInput,
    basicInformation,
    workHistoryTable,
    // availableTimeSheets,
    SupervisorList,
    BButton,
    BTable,
    BLink,
    BPagination,
    vSelect,
    flatPickr,
  },
  created() {
    this.getCompany();
    this.getEmployees();
    this.getRosterWorkList();
    this.getCustomers();
  },
  methods: {
    changeStatus(status, type) {
      if (type === "customer") {
        this.customerQuery.status = status;
        this.customerQuery.page = 1;
        this.getCustomers();
      }
      if (type === "employee") {
        this.empQuery.status = status;
        this.empQuery.page = 1;
        this.getEmployees();
      }
    },
    getCompany() {
      this.$store
        .dispatch("company/getCompany", this.$route.params.id)
        .then((response) => {
          this.company = response.data;
          if (this.company.info === null) {
            this.company.info = {
              industry: "",
              abn: "",
              acn: "",
              tfn: "",
              city: "",
              state: "",
            };
          }
        });
    },
    getCustomers(e) {
      if (e && typeof e === "number") {
        this.customerQuery.page = e;
      } else {
        this.customerQuery.page = 1;
      }
      if (this.logedInUser.roles[0].name === "admin") {
        this.customerQuery.company_id = this.logedInUser.id;
      }
      if (this.logedInUser.roles[0].name === "superadmin") {
        this.customerQuery.company_id = this.$route.params.id;
      }
      this.$store
        .dispatch("customer/getCustomers", this.customerQuery)
        .then((response) => {
          // reassigned the customers to the new array which has the customer_to_company.length > 0 in customer object
          let data = response.data.data;
          this.customers = data;
          this.totalCustomer = response.data.total;
          this.fromCustomer = response.data.from;
        });
    },
    getEmployees(e) {
      if (e && typeof e === "number") {
        this.empQuery.page = e;
      } else {
        this.empQuery.page = 1;
      }
      if (this.logedInUser.roles[0].name === "admin") {
        this.empQuery.user_id = this.$route.params.id;
        this.empQuery.company_id = this.$route.params.id;
      } else {
        this.empQuery.user_id = this.$route.params.id;
        this.empQuery.company_id = this.$route.params.id;
      }
      if (this.empQuery.status === undefined) {
        this.empQuery.status = 1;
      }
      this.$store
        .dispatch("employee/getEmployees", this.empQuery)
        .then((res) => {
          this.employees = res.data.data;
          this.totalEmployee = res.data.total;
          this.fromEmployee = res.data.from;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    clearDate() {
      this.rangeDate = null;
      this.query.date = "";
      this.query.end = "";
      this.getRosterWorkList();
    },
    getRosterWorkList() {
      if (this.logedInUser.roles[0].name === "admin") {
        this.query.user_id = this.$route.params.id;
      } else {
        this.query.user_id = this.$route.params.id;
      }
      if (this.rangeDate && this.rangeDate.length > 20) {
        // split the string in arrar
        let arr = this.rangeDate.split(" to ");
        this.query.date = arr[0];
        this.query.end = arr[1];
      }
      this.query.status = "";
      this.$store
        .dispatch("roster/getRosterbyId", this.query)
        .then((response) => {
          this.employeeWorkHistory = response.data.data;
          this.total = response.data.total;
        });
    },
  },
};
</script>
<style scoped>
.active-success {
  border-left: 4px solid #50c878;
  margin-right: 5px;
}

.active-danger {
  border-left: 4px solid #ff4f5d;
  margin-right: 5px;
}
.active-warning {
  border-left: 4px solid #ff9f43;
  margin-right: 5px;
}
.add-btn {
  border-color: white !important;

  /* Gradient */

  background: linear-gradient(#01185e 0%, #264296 44.92%, #7190ef);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  background-color: white;
  box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
}
</style>
<style lang="scss">
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
