<template>
  <div>
    <div class="table-responsive">
      <b-table
        responsive
        table-variant="sucess"
        striped
        hover
        :items="items"
        :fields="fields"
      >
        <template #cell(designation)="row">
          {{ row.item.supervisor.designation }}
        </template>
        <template #cell(address)="row">
          {{ row.item.supervisor.address }}
        </template>
        <template #cell(action)="row">
          <button v-if="row.item.status == '0'" class="btn btn-sm btn-info">
            <router-link
              v-if="$can('update', 'Supervisor')"
              class="text-white"
              :to="`/company/supervisor-edit/${$route.params.id}/${row.item.id}`"
            >
              Edit
            </router-link>
          </button>
          <b-button
            v-if="row.item.status == '0' && $can('suspend', 'Supervisor')"
            @click.prevent="suspendUser(row.item.id)"
            class="btn btn-danger btn-sm mx-1"
          >
            suspend
          </b-button>
          <!-- v-if="row.item.status == 'Suspended'" -->
          <b-button
            v-if="row.item.status == '1' && $can('withdrawal suspension', 'Supervisor')"
            @click.prevent="activeUser(row.item.id)"
            class="btn btn-success btn-sm mx-1"
          >
            Withdrawal Suspension
          </b-button>
        </template>
      </b-table>

      <b-pagination
        class="mt-3 p-1 d-flex justify-content-end"
        v-if="total > 0"
        v-model="query.page"
        :total-rows="total"
        :per-page="query.per_page"
        aria-controls="my-table"
        @change="getCountries($event)"
        pills
        size="lg"
        page-class="info"
      ></b-pagination>
    </div>
  </div>
</template>

<script>
import { toast } from "@/utils/toast";
import {
  BAvatar,
  BBadge,
  BButton,
  BCard,
  BCol,
  BContainer,
  BDropdown,
  BDropdownItem,
  BDropdownItemButton,
  BFormCheckbox,
  BFormGroup,
  BFormInput,
  BLink,
  BMedia,
  BPagination,
  BRow,
  BTable,
  BTooltip,
} from "bootstrap-vue";

import vSelect from "vue-select";

export default {
  components: {
    vSelect,
    BFormGroup,
    BFormCheckbox,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BTooltip,
    BDropdownItemButton,
    BContainer,
    BAvatar,
  },

  data() {
    return {
      total: 0,
      items: [],
      query: {
        page: 1,
        per_page: 50,
        user_id: this.$route.params.id,
      },

      fields: [
        {
          key: "name",
          label: "User Name",
          sortable: true,
        },

        {
          key: "phone",
          label: "phone",
          sortable: true,
        },
        {
          key: "email",
          label: "Email",
          sortable: true,
        },
        {
          key: "address",
          label: "Address",
          sortable: false,
        },
        {
          key: "designation",
          label: "Designation",
          sortable: true,
        },
        {
          key: "action",
          label: "Action",
          sortable: true,
        },
      ],
    };
  },
  created() {
    this.getSupervisors();
  },
  methods: {
    getSupervisors() {
      this.$store
        .dispatch("company/companyUserList", this.query)
        .then((response) => {
          this.items = response.data.data;
        });
    },
    suspendUser(item) {
      let data = {
        id: item,
        status: 1,
      };

      this.$store
        .dispatch("user/statusChange", data)
        .then((response) => {
          if (response.status_code === 200) {
            toast("Suspended", "CheckCircleIcon", "danger", "User Suspended");
            this.getSupervisors();
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    activeUser(item) {
      // statusChange

      let data = {
        id: item,
        status: 0,
      };
      this.$store
        .dispatch("user/statusChange", data)
        .then((response) => {
          if (response.status_code === 200) {
            toast("Activated", "CheckCircleIcon", "success", "User Activated");
            this.getSupervisors();
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>

<style scoped>
.active-success {
  border-left: 4px solid #50c878;
  margin-right: 5px;
}

.active-danger {
  border-left: 4px solid #ff4f5d;
  margin-right: 5px;
}

.add-btn {
  border-color: white !important;

  /* Gradient */

  background: linear-gradient(#01185e 0%, #264296 44.92%, #7190ef);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  background-color: white;
  box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
}
</style>
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
